import * as S from "./ArticleAuthors.sc";
import Link from "next/link";

// TODO - add authors in agrarlex
const ArticleAuthorsItem = ({ authors, contactData }) => {
  const {
    pk,
    image,
    name,
    company,
    specialization,
    user,
    phone,
    email,
  } = authors;
  return (
    <S.AuthorsContainer>
      <S.AvatarBox>
        <S.AvatarImage src={image} />
      </S.AvatarBox>
      <S.Info>
        <S.Name>{name}</S.Name>
        <S.Company>{contactData ? specialization : company}</S.Company>
        {contactData ? (
          <>
            <S.Phone>{phone}</S.Phone>
            {email && <S.Email href={`mailto:${email}`}>{email}</S.Email>}
          </>
        ) : (
          <S.Carrer>{specialization}</S.Carrer>
        )}
      </S.Info>
      {pk && !contactData && (
        <Link
          href={"/artykuly/redaktorzy/[journalist]"}
          as={`/artykuly/redaktorzy/${pk}`}
          passHref={true}
        >
          <a>
            <S.Button>wszystkie artykuły</S.Button>
          </a>
        </Link>
      )}
    </S.AuthorsContainer>
  );
};
export default ArticleAuthorsItem;
