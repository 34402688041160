import React from "react";
import * as S from "./ArticleAuthors.sc";
import ArticleAuthorsItem from "./ArticleAuthorsItem";
import AliceCarousel from "react-alice-carousel";

// TODO - add authors in agrarlex

const responsive = {
  360: { items: 1 },
  568: { items: 2 },
  768: { items: 3 },
  1024: { items: 4 },
  1200: { items: 5 },
};
const responsiveSmall = {
  360: { items: 1 },
  568: { items: 2 },
  1024: { items: 3 },
};

const ArticleAuthors = ({ authors, title, size }) => {
  if (authors) {
    return (
      <S.Container size={size}>
        <S.Header>{title}</S.Header>
        <AliceCarousel
          mouseTracking
          responsive={size === "small" ? responsiveSmall : responsive}
          disableDotsControls
          paddingLeft={10}
          paddingRight={10}
        >
          {authors.map((el) => (
            <ArticleAuthorsItem key={`authors${authors.pk}`} authors={el} />
          ))}
        </AliceCarousel>
      </S.Container>
    );
  }
  return null;
};

export default ArticleAuthors;
