import styled from "styled-components";
import { device } from "../../services/styles";

export const Container = styled.div`
  padding: 10px 0;
  max-width: 1150px;
  width: 100%;
  margin: ${(props) => (props.size === "small" ? "40px auto" : "88px auto")};
  @media (min-width: 360px) and (max-width: 768px) {
    padding: 10px 20px;
    margin: 40px auto 0 auto;
    height: auto;
  }
`;

export const Header = styled.p`
  display: block;
  font-size: 28px;
  line-height: 32px;
  font-weight: bold;
  text-align: center;
  padding: 0;
  margin: 0;
`;

export const ListAuthors = styled.div`
  display: flex;
`;

export const AuthorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 200px;
  min-height: 236px;
  padding: 10px;
  margin: 20px 10px 30px 10px;
  background-color: #ffffff;
  transition: box-shadow ease-out;
  transition-duration: 0.4s;
  transition-delay: 0.1s;
  border-radius: 20px;
  @media (min-width: 360px) and (max-width: 768px) {
    margin: 20px auto 30px auto;
  }
`;

export const AvatarBox = styled.div`
  position: relative;
  overflow: hidden;
  width: 96px;
  height: 96px;
  border-radius: 50%;
  margin-bottom: 8px;
`;

export const AvatarImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: inherit;
  transform: translate(-50%, -50%);
  width: 100%;
  object-fit:cover;
  min-height:96px;
`;

export const Info = styled.div`
  display: block;
`;

export const Name = styled.p`
  display: block;
  text-align: center;
  font-size: 18px;
  color: #000000;
  font-weight: 900;
  line-height: 24px;
  margin: 0 0 3px 0;
`;

export const Company = styled.p`
  display: block;
  font-size: 14px;
  color: #9b9b9b;
  text-align: center;
  line-height: 14px;
  margin: 0 0 10px 0;
`;

export const Carrer = styled.p`
  display: block;
  padding: 0;
  font-size: 14px;
  font-weight: 600;
  color: #000000;
  text-align: center;
  line-height: 14px;
  margin: 10px 0;
`;

export const LinkContainer = styled.div`
  margin: 20px auto 0;
`;

export const LinkHtml = styled.div`
  display: flex;
  justify-content: center;
  width: 200px;
  margin: 20px auto 0 auto;
`;

export const ButtonHref = styled.span`
  font-size: 16px;
  color: #4762ab;
  text-transform: uppercase;
  display: block;
  width: 200px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #4762ab;
  border-radius: 10px;
  transition: all 0.2s ease-in;
  &:hover {
    background-color: #4762ab;
    color: #ffffff;
  }
`;

export const Button = styled.p`
  display: block;
  color: #4762ab;
  font-size: 14px;
`;

export const Phone = styled.p`
  font-size: 14px;
  line-height: 1;
  margin: 10px 0;
  text-align: center;
`;

export const Email = styled.a`
  color: #456dd6;
  font-size: 14px;
  line-height: 1;
  margin: 10px 0;
  text-align: center;
  display: block;
  text-decoration: underline;
`;
