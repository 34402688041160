export const flattenArrayOfArrays = (array) =>
  array.reduce((a, b) => a.concat(b));

export const buildIncrementingArray = (length, startValue) => {
  const array = new Array(length);
  const incrementingArray = [...array.keys()];
  return incrementingArray.map((el) => el + startValue);
};

export const flattenResponsesArray = (data) =>
  data.map((el) => el[0]).filter((el) => el !== null);
